import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import { PutObjectCommand, S3 } from "@aws-sdk/client-s3";
import { useNavigate } from "react-router-dom";
import XMLParser from 'react-xml-parser';
import { useTranslation } from "react-i18next";

import CrossIcon from '../../assets/images/crossIcon.png';
import MasterCard from "../../assets/images/visa.png";
import VisaCard from "../../assets/images/master.png";

import TabelaeKorsise4 from '../../assets/images/TabelaeKorsise.4.png';
import TabelaeKorsise5 from '../../assets/images/TabelaeKorsise.5.png';
import shkollaveroreenotit from '../../assets/images/fitensi.jpg';
import KidsFitnes from '../../assets/images/fitensi.jpg';

import Shkollaenotit from '../../assets/images/shkolla-e-notit.jpg';
import { FaDownload } from 'react-icons/fa';  // Import the download icon from react-icons

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        outerHeight: '100px',
        border: 'solid blue 1px',
        borderRadius: '20px'
    },
};

export const Contract = ({ step, setStep, formData, registrations, setRegistrations, setFormData,form, setForm }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [orderID, setOrderID] = useState(null);
    const [sessionID, setSessionID] = useState(null);
    const [display, setDisplay] = useState('Terms');

    // useEffect(() => {
    //     if(step === 2 && registrations && registrations.length > 0) {

    //         if(registrations[0]?.type == "Individual") {
    //             // Define discount percentage based on total registrations
    //             const discountPercentage = registrations.length > 3 ? 20 : registrations.length > 2 ? 15 : 0; // 20% discount for 3 registrations, 15% for more than 2
        
    //             // Create a new array with updated registration objects
    //             const updatedRegistrations = registrations.map(registration => ({
    //                 ...registration, // Retain all existing fields
    //                 discount: discountPercentage,
    //                 payableAmount: registration.totalAmount - (registration.totalAmount * (discountPercentage / 100))
    //             }));
        
    //             // Update the state with the new array (assuming you have a setRegistrations function)
    //             setRegistrations(updatedRegistrations);
    //         } else if (registrations[0]?.type == "Shkolla e Notit") {
    //             // Define discount percentage based on total registrations
    //             const discountPercentage = registrations.length > 3 ? 15 : registrations.length > 1 ? 10 : 0; // 20% discount for 3 registrations, 15% for more than 2
    //             // Create a new array with updated registration objects
    //             const updatedRegistrations = registrations.map(registration => ({
    //                 ...registration, // Retain all existing fields
    //                 discount: discountPercentage,
    //                 payableAmount: registration.totalAmount - (registration.totalAmount * (discountPercentage / 100))
    //             }));
        
    //             // Update the state with the new array (assuming you have a setRegistrations function)
    //             setRegistrations(updatedRegistrations);
    //         }
    //     }
    // }, [step]);

    const handlePaymentMethodClick = (method) => {
        const updatedRegistrations = registrations.map(registration => ({
            ...registration,
            paymentMethod: method
        }));

        setRegistrations(updatedRegistrations);
    };

    const handleRegistration = async () => {
        try {
            const requestBody = registrations;
            let response;
            if (registrations[0]?.type === "Shkolla e Notit") {
                response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/swimmingschool/register`, requestBody);
            } else {
                response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/registeration/register`, requestBody);
            }

            setRegistrations([]);
            setFormData({
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                address: '',
                phone: '',
                email: '',
                time: 0,        //months or appointments per week(kids fitness)
                timings: '',    //for kids fitness
                days: [],       //for kits fitness
                totalAmount: 0,
                payableAmount: 0,
                discount: 0,
                kidEntries: 0,
                swimmingExpertise: '',
                healthProblems: null,
                healthProblemDescription: '',
                type: 'Individual',
                premium: false,
                batchId: '0',
                paymentMethod: '',
                entries: '',
                ageGroup: '',
                age: 0,
                gender: ""

            });
            setForm("Individual")

            toast.success('Registration Successful');
            return response.data;
        } catch (error) {
            console.error('Error adding the user:', error.message);
            toast.error('Registration Failed. ', error.message);
        }
    };

    function getDateAfterMonths(months) {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setMonth(currentDate.getMonth() + months);
        const formattedDate = futureDate.toISOString().split('T')[0];
        return formattedDate;
    }

    const [date, setDate] = useState();
    const [currentDate, setCurrentDate] = useState();

    useEffect(() => {
        if (registrations && registrations.length > 0) {
            const date = getDateAfterMonths(registrations[0].time ? registrations[0].time : 0);
            const currentDate = new Date();
            const formattedCurrentDate = currentDate.toISOString().split('T')[0];
            setCurrentDate(formattedCurrentDate);
            setDate(date);
        }
    }, [registrations]);

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const handlePayment = async () => {
        const registration = await handleRegistration();

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/registeration/makePayment`, {
            email: registrations[0]?.email,
            phone: registrations[0]?.phone,
            amount: getPayableAmount(),
            registrationId: registration[0]._id,

        });

        const jsonObj = new XMLParser().parseFromString(response.data);
        const url = jsonObj.getElementsByTagName('URL')[0].value;
        const orderId = jsonObj.getElementsByTagName('OrderID')[0].value;
        setOrderID(orderId);
        localStorage.setItem('orderID', orderId);
        const sessionId = jsonObj.getElementsByTagName('SessionID')[0].value;
        localStorage.setItem('sessionID', sessionId);
        setSessionID(sessionId);

        window.location.href = `${url}?ORDERID=${orderId}&SESSIONID=${sessionId}`;
    };



    const getTotalAmount = () => {
        const totalAmount = registrations?.reduce((acc, registration) => acc + registration.totalAmount, 0);
        return totalAmount;
    };

    const getPayableAmount = () => {
        const payableAmount = registrations?.reduce((acc, registration) => acc + registration.payableAmount, 0);
        return payableAmount;
    };

    const [paymentMethod, setPaymentMethod] = useState('');

    return (
        <div className={`lg:w-[80vw] w-[90vw] min-h-[100vh] bg-white rounded-3xl pt-10 pb-10 mb-10 flex flex-col font-custom2 ${display == "Terms" ? 'lg:px-20 px-5' : 'px-5'}`}>
            {
                display == "Terms"
                    ? <TermsAndContitions currentDate={currentDate} date={date} />
                    : registrations[0]?.type == "Individual" ? <IndividualSchedual />
                        : registrations[0]?.type == "Shkolla e Notit" ? <SwimmingSchool />
                            : registrations[0]?.type == "kids fitness" ? <KidsFitness />
                                : <ParentsGuidlines />
            }

            <div className="flex flex-row items-end justify-end w-full gap-2 mt-20 font-bold">
                <button
                    onClick={() => {
                        if (display == "Schedual") {
                            setDisplay("Terms");
                        } else {
                            setStep(1);
                        }
                    }}
                    className="px-5 py-2 text-gray-500 transition-all duration-200 bg-gray-100 rounded-lg max-w-fit hover:bg-gray-200 hover:scale-105">{t('cancel')}</button>
                <button
                    onClick={() => {
                        if (display == "Terms") {
                            setDisplay("Schedual");
                        } else {
                            openModal();
                        }
                    }}
                    className="px-5 py-2 text-white transition-all duration-200 bg-blue-600 rounded-lg max-w-fit hover:bg-blue-800 hover:scale-105"> {t('accept')}</button>
            </div>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='flex justify-end w-full'>
                    <img src={CrossIcon} alt='close' className='w-5 h-5 cursor-pointer' onClick={closeModal} />
                </div>
                {
                    formData.type == "Shkolla e Notit"
                        ?
                        <div className={`text-left flex flex-col gap-3 p-5 px-5 md:px-10 `}>
                            <p>{t('schedule_jointly')}</p>
                            <p>{t('payment_link_email')}</p>
                            <div className="flex flex-row items-end justify-end w-full gap-5 mt-5">
                                <button onClick={async () => {
                                    closeModal();
                                    await handleRegistration();
                                    setStep(1);
                                }} className="p-2 px-5 transition-all duration-200 border-2 border-blue-500 md:px-10 rounded-xl hover:bg-blue-500">{t('register')}</button>
                            </div>
                        </div>

                        :
                        <div className={`flex flex-col items-center justify-center gap-3 p-5 px-5 md:px-20 text-center`}>
                            <div className="py-5 w-full border-b-[1px] border-[#0045B2]">
                                <span className={`${getTotalAmount() == getPayableAmount() ? 'hidden' : 'block'} text-[#0045B2] font-semibold p-2 border-[1px] border-[#0045B2] rounded-md`}>
                                    Anëtarësim Individual (Bazik)  Total : 
                                        <span className="relative mr-2">
                                            <div className="absolute left-0 w-full border-b-2 border-black top-2"></div>
                                            {getTotalAmount()}€
                                        </span> 
                                    {getPayableAmount()}€
                                </span>
                                <span className={`${getTotalAmount() == getPayableAmount() ? 'block' : 'hidden'} text-[#0045B2] font-semibold p-2 border-[1px] border-[#0045B2] rounded-md`}>
                                    Anëtarësim Individual (Bazik)  Total : 
                                        <span className="relative mr-2">
                                            {getTotalAmount()}€
                                        </span> 
                                </span>
                            </div>
                            <div className="text-[#0045B2] font-semibold p-2 border-[1px] border-[#0045B2] rounded-md flex flex-row gap-2 w-full justify-between">
                                <div className="flex flex-row gap-2">
                                    <input onClick={() => { handlePaymentMethodClick("card"); setPaymentMethod('card'); }} type="radio" id="paymentMethod" name="paymentMethod" />
                                    <span>{t('pay_with_card')}</span>
                                </div>
                                <div className="flex flex-row gap-3">
                                    <img src={MasterCard} className="w-8 h-5" />
                                    <img src={VisaCard} className="w-8 h-5" />
                                </div>
                            </div>
                            <div className="text-[#0045B2] font-semibold p-2 border-[1px] border-[#0045B2] rounded-md flex flex-row gap-2 w-full">
                                <input onClick={() => { handlePaymentMethodClick("cash"); setPaymentMethod('cash'); }} type="radio" id="paymentMethod" name="paymentMethod" />
                                <span>{t('pay_with_cash')}</span>
                            </div>
                            <div className="flex flex-row items-end justify-end w-full gap-5 mt-5">
                                <button onClick={async () => {
                                    if (paymentMethod == '') {
                                        toast.warning(t('select_payment_method'));
                                    } else {
                                        if (paymentMethod == 'cash') {
                                            closeModal();
                                            handleRegistration();
                                            setStep(1);
                                        } else {
                                            closeModal();
                                            setStep(1);
                                            await handlePayment();
                                        }
                                    }
                                }} className="p-2 px-10 transition-all duration-200 border-2 border-blue-500 rounded-xl hover:bg-blue-500">{t('pay')}</button>
                            </div>
                        </div>
                }
            </Modal>
        </div>
    )
};

const IndividualSchedual = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center justify-center w-full gap-5 md:flex-row">
            <div className="flex flex-col items-center">
                <img src={TabelaeKorsise4}  />
                <a href={TabelaeKorsise4} download="TabelaeKorsise4.jpg" className="flex items-center px-5 py-2 mt-2 text-white transition-all duration-200 bg-blue-600 rounded-lg max-w-fit hover:bg-blue-800 hover:scale-105">
                    {t('download_image')} <FaDownload className="ml-2" />
                </a>
            </div>
            <div className="flex flex-col items-center">
                <img src={TabelaeKorsise5}  />
                <a href={TabelaeKorsise5} download="TabelaeKorsise5.jpg" className="flex items-center px-5 py-2 mt-2 text-white transition-all duration-200 bg-blue-600 rounded-lg max-w-fit hover:bg-blue-800 hover:scale-105">
                    {t('download_image')} <FaDownload className="ml-2" />
                </a>
            </div>
        </div>
    );
};

const ParentsGuidlines = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <img src={shkollaveroreenotit} className="sm:w-[90%] lg:w-[50%] w-[100%]" />
            <a href={shkollaveroreenotit} download="shkolla-e-notit.jpg" className="flex items-center px-5 py-2 mt-2 text-white transition-all duration-200 bg-blue-600 rounded-lg max-w-fit hover:bg-blue-800 hover:scale-105">
                {t('download_image')} <FaDownload className="ml-2" />
            </a>
        </div>
    );
};

const SwimmingSchool = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <img src={Shkollaenotit} className="sm:w-[90%] lg:w-[50%] w-[100%]" />
            <a href={Shkollaenotit} download="shkollaenotit.jpg" className="flex items-center px-5 py-2 mt-2 text-white transition-all duration-200 bg-blue-600 rounded-lg max-w-fit hover:bg-blue-800 hover:scale-105">
                {t('download_image')} <FaDownload className="ml-2" />
            </a>
        </div>
    );
};

const KidsFitness = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <img src={KidsFitnes} className="sm:w-[90%] lg:w-[50%] w-[100%] sm:h-[60vh] lg:h-[70vh] h-[70vh]" />
            <a href={KidsFitnes} download="fitnesi.jpg" className="flex items-center px-5 py-2 mt-2 text-white transition-all duration-200 bg-blue-600 rounded-lg max-w-fit hover:bg-blue-800 hover:scale-105">
                {t('download_image')} <FaDownload className="ml-2" />
            </a>
        </div>
    );
};

const TermsAndContitions = ({ currentDate, date }) => {
    const { t } = useTranslation();

    const formatDate = (dateString) => {
        // Ensure dateString is a valid string
        if (typeof dateString !== 'string') {
          return '';
        }
      
        const parts = dateString?.split('-');
        // Ensure parts has exactly three components: [year, month, day]
        if (parts.length !== 3) {
          return '';
        }
      
        const [year, month, day] = parts;
        return `${day}/${month}/${year}`;
      };
      
    
    return (
        <>
            <div className="flex flex-col items-center justify-center w-full text-center">
                <div className="flex flex-col w-[90%] sm:w-[65%] lg:w-[45%] items-center justify-center text-center">
                    <h1 className="text-4xl font-bold text-gray-900">{t("membership_contract")}</h1>
                    <p>{t("read_carefully")}</p>
                </div>
            </div>
            <div className="flex flex-col w-full px-0 mt-10 text-gray-600 lg:px-20">
                <p><b>{t("individual_membership")}</b> {t("individual_membership_text")}</p>
                <p><b>{t("pool_trainer")}</b> {t("pool_trainer_text")}</p>
                <p><b>{t("membership_period")}</b> {t("membership_period_text")}</p>
                <p className="text-red-500"><b>{t("payment_prices")}</b> {t("payment_prices_text")}</p>
                <p><b>{t("health_physical_aspect")}</b> {t("health_physical_aspect_text")}</p>
                <p><b>{t("damage_responsibility")}</b> {t("damage_responsibility_text")}</p>
                <p><b>{t("contract_duration")}</b> <span className="underline">{formatDate(currentDate)}</span> {t("to")} <span className="underline">{formatDate(date)}</span></p>
            </div>
        </>
    );
};
