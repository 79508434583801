import { useEffect, useState } from "react";
import axios from "axios";
import XMLParser from "react-xml-parser";
import blueLines from "../assets/images/blue-lines.png";
import blueBlur from "../assets/images/bg-blue-blur.png";
import { useTranslation } from "react-i18next";

export const Account = () => {
    const { t } = useTranslation();
    const [account, setAccount] = useState(null);

    const getAccount = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user);
        if (user) {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/${user.email}`);
            setAccount(response.data.user);
            console.log('test', response.data.user?.registrations);
            if (response.data.user?.registrations.length > 0) {
                for (let i = 0; i < response.data.user?.registrations?.length; i++) {
                    console.log('here');
                    getPaymentStatus(response.data.user?.registrations[i]?.paymentId, response.data.user?.registrations[i]?.sessionId);
                }
            }
        } else {
            window.location.href = '/login';
        }
    };

    useEffect(() => {
        getAccount();
        //localStorage.clear();
    }, []);

    const getPaymentStatus = async (id, session) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/registeration/getPaymentStatus`, { id, session });
            const jsonObj = new XMLParser().parseFromString(response.data);
            const status = jsonObj.getElementsByTagName('OrderStatus')[0].value;
            setAccount(prevState => {
                const updatedRegistrations = prevState.registrations.map(registration => {
                    if (registration.paymentId && registration.paymentId === id) {
                        registration.paymentStatus = status;
                    }
                    return registration;
                });
                return { ...prevState, registrations: updatedRegistrations };
            });
        } catch (error) {
            console.error('Error getting payment status:', error.message);
        }
    };

    return (
        <div className="relative flex items-center justify-center mt-40 align-middle font-custom2">
            <img src={blueBlur} alt="blue Blur"
                className="min-w-[100vw] min-h-[60vh] md:min-h-[40vh] h-[60vh] absolute -z-10 -top-20 sm:-top-20" />
            <img src={blueLines} alt="blue lines"
                className="min-w-[100vw] min-h-[40vh] md:min-h-[40vh] max-h-[40vh] absolute -z-10 -top-26 sm:top-26 rotate-6" />
            {account && (
                <div className="flex flex-col gap-5 p-5 text-center bg-white w-5/5 sm:w-5/5 rounded-2xl md:w-5/5 xl:w-2/3 mb-10">
                    <h1 className="mb-5 text-3xl font-bold">{t("account.registrations")}</h1>
                    <div className="grid w-full grid-cols-6 gap-2 mb-3 text-xs sm:grid-cols-7 sm:text-base text-start md:gap-5">
                        <span className="col-span-1 sm:col-span-2">{t("account.name")}</span>
                        <h2>{t("account.type")}</h2>
                        <h3>{t("account.payment")}</h3>
                        <h4>{t("account.payment_status")}</h4>
                        <h4>{t("account.subscription_time")}</h4>
                        <h4>{t("account.entries")}</h4>
                    </div>
                    {
                        account && account?.registrations.map((registration, index) => (
                            <div className="grid w-full grid-cols-6 gap-2 p-2 text-xs rounded-lg sm:grid-cols-7 sm:text-base bg-blue-50 text-start md:gap-5" key={index}>
                                <span className="col-span-1 sm:col-span-2">{registration?.firstName} {registration?.lastName}</span>
                                <h2>{registration?.type}</h2>
                                <h3>{registration?.paymentMethod}</h3>
                                <h4>{registration?.paymentStatus}</h4>
                                <h4>{registration?.time} Muaj</h4>
                                <h4>{registration?.totalEntries}</h4>
                                <h4>{registration?.remainingEntries}</h4>
                                <h4>{registration?.userStatus}</h4>
                            </div>
                        ))
                    }
                    <div className="flex items-end justify-end mt-5">
                        <button className="p-2 px-5 bg-blue-300 rounded-lg" onClick={() => {
                            localStorage.removeItem('user');
                            localStorage.removeItem('token');
                            window.location.href = '/login';
                        }}>{t("account.logout")}</button>
                    </div>
                </div>
            )}
        </div>
    );
}
