import React, { useState } from "react";

export const IndividualDiscountCard = ({ formData, setFormData, discountData }) => {
    // Set default selection to 1 month with 4 entries and 17.5 € for Anetaresi Bazike
    const defaultMonth = discountData?.months.find(month => month.time === 1);
    const defaultOption = defaultMonth?.options?.find(option => option.entries === 8 && option.cost === 35);

    const [dropDown, setDropDown] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(defaultMonth || discountData?.months[0]);
    const [selectedOption, setSelectedOption] = useState(defaultOption || null);
    const [optionsDropDown, setOptionsDropDown] = useState(false);

    // Calculate entries based on plan type
    const calculateEntries = (monthTime) => {
        if (discountData.entries === "unlimited") return "unlimited";
        if (discountData.entries === "variable") return ""; // Return empty to avoid showing "variable"
        const baseEntriesPerMonth = parseInt(discountData.entries, 10);
        return baseEntriesPerMonth * monthTime;
    };

    // Handle choosing a plan and setting the cost and entries
    const handleChosePlan = () => {
        if (selectedMonth.options && !selectedOption) {
            alert("Please select an entry option."); // Prevent setting if no option is selected
            return;
        }

        const calculatedEntries = calculateEntries(selectedMonth.time);
        const cost = selectedOption ? selectedOption.cost : selectedMonth.cost;
        const entries = selectedOption ? selectedOption.entries : calculatedEntries;

        setFormData((prevData) => ({
            ...prevData,
            time: selectedMonth.time,
            totalAmount: cost,
            payableAmount: cost,
            entries: entries,
            premium: discountData.entries === "unlimited",
        }));
    };

    // Check if the current selection is already chosen
    const isSelected = () => {
        return (
            formData.time === selectedMonth.time &&
            formData.totalAmount === (selectedOption ? selectedOption.cost : selectedMonth.cost) &&
            formData.entries === (selectedOption ? selectedOption.entries : calculateEntries(selectedMonth.time))
        );
    };

    return (
        <div className="flex flex-col gap-5 border-[#003DFE] border-2 rounded-lg min-w-[300px] p-5 max-h-[300px] h-fit font-custom2">
            {/* Month Dropdown */}
            <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                <button
                    onClick={() => setDropDown(!dropDown)}
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                    type="button"
                >
                    {selectedMonth?.time} muaj
                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>

                <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown ? 'block' : 'hidden'}`}>
                    <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                        {discountData?.months.map((month, index) => (
                            <li key={index}>
                                <a
                                    onClick={() => { setSelectedMonth(month); setSelectedOption(null); setDropDown(false); }}
                                    href="#"
                                    className="block px-4 py-2 hover:bg-gray-200"
                                >
                                    {month.time} muaj
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Entry Options Dropdown for Basic Membership */}
            {discountData.entries === "variable" && selectedMonth?.options && (
                <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                    <button
                        onClick={() => setOptionsDropDown(!optionsDropDown)}
                        className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                        type="button"
                    >
                        {selectedOption ? `${selectedOption.entries} hyrje - ${selectedOption.cost} €` : "Zgjidh opsionin"}
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>

                    <div className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${optionsDropDown ? 'block' : 'hidden'}`}>
                        <ul className="py-2 text-sm">
                            {selectedMonth.options.map((option, index) => (
                                <li key={index}>
                                    <a
                                        onClick={() => { setSelectedOption(option); setOptionsDropDown(false); }}
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-200"
                                    >
                                        {option.entries} hyrje - {option.cost} €
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            <div className="flex flex-col gap-3">
                {/* Display entries and cost */}
                <span>Hyrjet: {selectedOption ? selectedOption.entries : (calculateEntries(selectedMonth.time) || "")}</span>
                <span>Çmimi: {selectedOption ? selectedOption.cost : selectedMonth?.cost} €</span>
                {/* <span
  style={{
    display: "inline-block",

    fontSize: "18px",
    fontWeight: "bold",
    color: "black",
    textTransform: "uppercase", // Uppercase for emphasis
    padding: "5px 10px",
    borderRadius: "6px",
    textAlign: "center",
    letterSpacing: "1px",
    backgroundClip: "text",
   
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
  }}
>
  <b style={{ color: "black", fontSize: "16px" }}>Black Friday: </b>
  <b style={{ color: "red", fontSize: "18px", textShadow: "1px 1px 3px rgba(255, 0, 0, 0.5)" }}>
  Zbritje deri në 50%
  </b>
</span> */}

             
                
                <button 
                    onClick={handleChosePlan}
                    className={`min-w-full border-[#003DFE] font-bold border-2 rounded-lg p-1 hover:bg-[#003DFE] hover:text-white transition-all duration-300 
                        ${isSelected() ? 'bg-[#003DFE] text-white' : 'text-[#003DFE]'}`}
                >
                    {isSelected() ? 'Përzgjidh Planin' : 'Zgjidh Planin'}
                </button>
            </div>
        </div>
    );
};
