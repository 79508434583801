import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";

export const Denied = () => {
    const { t } = useTranslation();

    const handlePaymentCheck =  async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/registeration/checkPayment`, {
            id: localStorage.getItem('orderID'),
            session: localStorage.getItem('sessionID'),
        });
    }

    useEffect(() => {
        handlePaymentCheck();
    }, []);

    return (
        <div className="mt-20 flex flex-col items-center font-custom2">
            <div className="bg-red-100 flex flex-col gap-2 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">{t("denied")}!</strong>
                <span className="block sm:inline">{t("deniedmessages")}</span>
            </div>
            <div className="mt-4">
                {/* <h3>{t("successid")}: {id}</h3> */}
            </div>
        </div>
    );
};