import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const FitnessKidDiscountCard = ({ formData, setFormData, discountData }) => {
    const [dropDown, setDropDown] = useState(false);
    const [dropDown2, setDropDown2] = useState(false);
    const [appointments, setAppointments] = useState("2");
    const [entries, setEntries] = useState(8);
    const [payment, setPayment] = useState(30);
    const [time, setTime] = useState("18:00");
    const [saturdayTime, setSaturdayTime] = useState("Select Time Slot");
    const [dropDownSaturday, setDropDownSaturday] = useState(false);
    const [plan, setPlan] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);
    const { t } = useTranslation();

    // Define constant mapping between displayed (translated) days and database days
    const DAY_MAPPING = {
        [t('monday')]: 'MONDAY',
        [t('tuesday')]: 'TUESDAY',
        [t('wednesday')]: 'WEDNESDAY',
        [t('thursday')]: 'THURSDAY',
        [t('friday')]: 'FRIDAY',
        [t('saturday')]: 'SATURDAY'
    };

    // Reverse mapping for display purposes
    const REVERSE_DAY_MAPPING = Object.entries(DAY_MAPPING).reduce((acc, [key, value]) => {
        acc[value] = key;
        return acc;
    }, {});

    const handleDayClick = (displayDay) => {
        const dbDay = DAY_MAPPING[displayDay];
        const isSaturday = dbDay === 'SATURDAY';
        const slotTime = isSaturday ? '08:00' : time;
    
        if (timeSlots.some(slot => slot.day === dbDay)) {
            setTimeSlots(timeSlots.filter(slot => slot.day !== dbDay));
        } else if (timeSlots.length < Number(appointments)) {
            setTimeSlots([...timeSlots, { day: dbDay, time: slotTime }]);
        }
    };

    const handleSaturdayTimeClick = (selectedTime) => {
        const dbDay = 'SATURDAY';
        const saturdaySlotIndex = timeSlots.findIndex(slot => slot.day === dbDay);
    
        if (saturdaySlotIndex !== -1) {
            const updatedTimeSlots = [...timeSlots];
            updatedTimeSlots[saturdaySlotIndex].time = selectedTime;
            setTimeSlots(updatedTimeSlots);
        } else if (timeSlots.length < Number(appointments)) {
            setTimeSlots([...timeSlots, { day: dbDay, time: selectedTime }]);
        }
    };

    const handleChosePlan = () => {
        if (timeSlots.length < Number(appointments)) {
            toast.warning(`Please select ${appointments} days`);
            return;
        }
        
        setFormData((prevData) => ({
            ...prevData,
            days: timeSlots,
            time: 1,
            timings: time,
            entries: entries,
            totalAmount: payment,
            payableAmount: payment
        }));
    };

    const getPrice = () => {
        switch(appointments) {
            case "2":
                return "30 €";
            case "3":
                return "40 €";
            default:
                return "20 €";
        }
    };

    return (
        <div className="flex flex-col gap-5 border-[#003DFE] border-2 rounded-lg min-w-[300px] p-5 max-h-[500px] h-fit font-custom2">
            {/* Appointments per week dropdown */}
            <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                <button
                    onClick={() => setDropDown(!dropDown)}
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                    type="button"
                >
                    {appointments}x {t('Week')}
                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>

                <div id="dropdown" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown ? 'block' : 'hidden'}`}>
                    <ul className="py-2 text-sm" aria-labelledby="dropdownDefaultButton">
                        {[
                            { value: "1", entries: 4, payment: 20 },
                            { value: "2", entries: 8, payment: 30 },
                            { value: "3", entries: 12, payment: 40 }
                        ].map((option) => (
                            <li key={option.value}>
                                <a
                                    href="#"
                                    className="block px-4 py-2 hover:bg-gray-200"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setAppointments(option.value);
                                        setDropDown(false);
                                        setEntries(option.entries);
                                        setPayment(option.payment);
                                        setTimeSlots([]);
                                    }}
                                >
                                    {option.value}x {t('Week')}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Time selection */}
            <div className="flex flex-row gap-2">
                <span className="p-1 px-3 text-blue-700">{t('time')}: </span>
                <div className="flex flex-col gap-2 rounded-2xl relative w-[200px]">
                    <button
                        onClick={() => setDropDown2(!dropDown2)}
                        id="dropdownTimeButton"
                        data-dropdown-toggle="dropdownTime"
                        className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                        type="button"
                    >
                        {time}
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>

                    <div id="dropdownTime" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDown2 ? 'block' : 'hidden'}`}>
                        <ul className="py-2 text-sm" aria-labelledby="dropdownTimeButton">
                            {["18:00", "19:00"].map((timeOption) => (
                                <li key={timeOption}>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-200"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTime(timeOption);
                                            setDropDown2(false);
                                        }}
                                    >
                                        {timeOption} - {parseInt(timeOption) + 1}:00
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* Schedule selection */}
            <div className="flex flex-col gap-1">
                <div className="p-1 px-3 text-blue-700">
                    {t('schedule')}
                    <div className="p-2 px-3 ml-5 w-full rounded-2xl bg-[#D5DFFF] bg-opacity-60 text-blue-700">
                        {/* First row of days */}
                        <div className="grid grid-cols-3 gap-2">
                            {[t('monday'), t('tuesday'), t('wednesday')].map(displayDay => (
                                <div
                                    key={displayDay}
                                    className={`border-[1px] rounded border-blue-700 px-2 cursor-pointer text-center ${
                                        timeSlots.some(slot => slot.day === DAY_MAPPING[displayDay])
                                            ? 'bg-blue-700 text-white rounded bg-opacity-75'
                                            : ''
                                    }`}
                                    onClick={() => handleDayClick(displayDay)}
                                >
                                    {displayDay}
                                </div>
                            ))}
                        </div>
                        {/* Second row of days */}
                        <div className="grid grid-cols-3 gap-2 mt-2">
                            {[t('thursday'), t('friday'), t('saturday')].map(displayDay => (
                                <div
                                    key={displayDay}
                                    className={`border-[1px] rounded border-blue-700 px-2 cursor-pointer text-center ${
                                        timeSlots.some(slot => slot.day === DAY_MAPPING[displayDay])
                                            ? 'bg-blue-700 text-white rounded bg-opacity-75'
                                            : ''
                                    }`}
                                    onClick={() => handleDayClick(displayDay)}
                                >
                                    {displayDay}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Saturday time selection */}
                {timeSlots.some(slot => slot.day === 'SATURDAY') && (
                    <div className="flex flex-col gap-2 rounded-2xl relative w-[200px] mt-2">
                        <button
                            onClick={() => setDropDownSaturday(!dropDownSaturday)}
                            id="dropdownSaturdayButton"
                            data-dropdown-toggle="dropdownSaturday"
                            className="text-blue-700 bg-[#D5DFFF] bg-opacity-60 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                            type="button"
                        >
                            {saturdayTime}
                            <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                            </svg>
                        </button>

                        <div id="dropdownSaturday" className={`z-10 bg-white text-black -right-1 top-10 absolute divide-y divide-gray-100 rounded-lg shadow w-44 ${dropDownSaturday ? 'block' : 'hidden'}`}>
                            <ul className="py-2 text-sm" aria-labelledby="dropdownSaturdayButton">
                                {[
                                    { time: "09:00", display: "09:00 - 10:00" },
                                    { time: "10:00", display: "10:00 - 11:00" }
                                ].map((option) => (
                                    <li key={option.time}>
                                        <a
                                            href="#"
                                            className="block px-4 py-2 hover:bg-gray-200"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleSaturdayTimeClick(option.time);
                                                setDropDownSaturday(false);
                                                setSaturdayTime(option.time);
                                            }}
                                        >
                                            {option.display}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}

                <span className="pl-10 mb-5">
                    {t('choose')} {appointments} {t('days_of_week')}
                </span>
                <div className="pl-10 mb-5">
                    {getPrice()}
                </div>
                <button
                    onClick={() => {
                        setPlan(!plan);
                        handleChosePlan();
                    }}
                    className={`min-w-full border-[#003DFE] font-bold border-2 rounded-lg p-1 hover:bg-[#003DFE] hover:text-white transition-all duration-300 ${
                        plan ? 'bg-[#003DFE] text-white' : 'text-[#003DFE]'
                    }`}
                >
                    {plan ? t('unChoosePlan') : t('choosePlan')}
                </button>
            </div>
        </div>
    );
};